const initialValue = {
    updateEvent: "",
    updateVenue:"",
    updateCompany:"",
    token:""
}

export default function eventReducer(state = initialValue, action) {
    switch (action.type) {
        case "UPDATE_EVENT":
            return { ...state, updateEvent: action.payload };
        case "UPDATE_VENUE":
            return { ...state, updateVenue: action.payload };
        case "UPDATE_COMPANY":
            return { ...state, updateCompany: action.payload };
        case "GET_TOKEN":
            return { ...state, token: action.payload };
        default:
            return state
    }
}