export default function updateEvent(data) {
    return {
        type: "UPDATE_EVENT",
        payload: data
    }
}
export  function updateVenue(data) {
    return {
        type: "UPDATE_VENUE",
        payload: data
    }
}
export  function updateCompany(data) {
    return {
        type: "UPDATE_COMPANY",
        payload: data
    }
}
export  function getToken(data) {
    return {
        type: "GET_TOKEN",
        payload: data
    }
}