import AuthGuard from "components/authentication/AuthGuard";
import GuestGuard from "components/authentication/GuestGuard";
import DashboardLayout from "components/Layouts/DashboardLayout";
import LoadingScreen from "components/LoadingScreen";
import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router-dom";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/ForgetPassword"))
);

// Dashboard pages
const DashboardSaaS = Loadable(lazy(() => import("./pages/dashboards/SaaS")));

// user profile
const UserProfile = Loadable(lazy(() => import("./pages/UserProfile")));

// user management
const UserList = Loadable(
  lazy(() => import("./pages/userManagement/UserList"))
);
const Events = Loadable(
  lazy(() => import("./pages/userManagement/Events"))
);
const Companies = Loadable(
  lazy(() => import("./pages/userManagement/Companies"))
);
const Venues = Loadable(
  lazy(() => import("./pages/userManagement/Venues"))
);
const AddNewEvent = Loadable(
  lazy(() => import("./pages/userManagement/AddNewEvent"))
);
const AddNewCompany = Loadable(
  lazy(() => import("./pages/userManagement/AddNewCompany"))
);
const AddNewVenue = Loadable(
  lazy(() => import("./pages/userManagement/AddNewVenue"))
);
const UpdateEvent = Loadable(
  lazy(() => import("./pages/userManagement/UpdateEvent"))
);
const UpdateVenue = Loadable(
  lazy(() => import("./pages/userManagement/UpdateVenue"))
);
const UpdateCompany = Loadable(
  lazy(() => import("./pages/userManagement/UpdateCompany"))
);

// error
const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: "forget-password",
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "user-profile",
        element: <UserProfile />,
      },

      {
        path: "user-list",
        element: <UserList />,
      },
      {
        path: "events",
        element: <Events />,
      },
      {
        path: "companies",
        element: <Companies />,
      },
      {
        path: "venues",
        element: <Venues />,
      },
      {
        path: "add-event",
        element: <AddNewEvent />,
      },
      {
        path: "add-company",
        element: <AddNewCompany />,
      },
      {
        path: "add-venue",
        element: <AddNewVenue />,
      },
      {
        path: "edit-event",
        element: <UpdateEvent />,
      },
      {
        path: "edit-venue",
        element: <UpdateVenue />,
      },
      {
        path: "edit-company",
        element: <UpdateCompany />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
