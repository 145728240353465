import { SvgIcon, SvgIconProps } from "@mui/material";

const FacebookIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.53945 2.65667H8V0.112667C7.74802 0.078 6.88143 0 5.87218 0C3.76635 0 2.32381 1.32467 2.32381 3.75933V6H0V8.844H2.32381V16H5.1729V8.84467H7.40272L7.75669 6.00067H5.17224V4.04133C5.1729 3.21933 5.39422 2.65667 6.53945 2.65667Z"
        fill="#2174D8"
      />
    </SvgIcon>
  );
};

export default FacebookIcon;
